export function initStickies() {

    // bail out IE
    if (typeof IntersectionObserver === 'undefined')
        return;

    var cta = document.querySelector('.sticky');
    var header = document.querySelector('header');
    var target = document.querySelector('.sticky-side');

    var options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.0
    }

    var observer = new IntersectionObserver(function(entries, observer) {

        // console.log(entries, observer);
        // var intersecting = entries.reduce((acc, cur) => cur.isIntersecting || acc );
        var intersecting = entries.some(function(e) { return e.isIntersecting });
        // console.log(intersecting);

        if (intersecting) {
            cta.style.opacity = 0.0;
            cta.style.zIndex = -1000;
        } else {
            cta.style.opacity = 1.0;
            cta.style.zIndex = 10;
        }

    }, options);

    if (cta) {

        observer.observe(header);

        if (target) {
            observer.observe(target);
        }
    }
}