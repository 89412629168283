export const selectizeOptions = {

    searchField: ['name', 'address'],
    sortField: [
        {"field": "name", "direction": "asc"},
        {"field": "$score", "direction": "desc"},
    ],
    openOnFocus: false,
    closeAfterSelect: true,

    optgroups: [
        {$order: 1, id: 'neighborhood', name: 'Neighbourhoods'},
        {$order: 2, id: 'building', name: 'Buildings'},
        {$order: 3, id: 'listing', name: 'Listings'},
    ],

    optgroupField: 'type',
    optgroupLabelField: 'name',
    optgroupValueField: 'id',
    lockOptgroupOrder: true,
    valueField: 'id',

    render: {

        // eslint-disable-next-line
        item:  function(item, escape) {
            return '<div><span class="building-name">' + item.name + '</span></div>';
        },

        option: function(item, escape) {

            return '<div class="option">' +
                '<span class="building-name">' + escape(item.name) + '</span>' +
                (item.address ? ('<span class="building-address">' + escape(item.address) + '</span>') : '') +
                (item.region ? ('<span class="building-address">' + escape(item.region) + '</span>') : '') +
            '</div>';
        },
    },
};