$(function(){
    $("#show-filter-button").click(function(ev){

        ev.preventDefault();

        var reflow = $("#mobile-filter-reflow");
        var sidebar = $("#sidebar");
        var filter = $(".filter");

        var filterShowing = $(".filter", reflow).length > 0;
        // console.log(formShowing);

        if (!filterShowing) {
            reflow.append(filter);
            filter.hide().slideDown(200);
        } else {
            filter.slideUp(200, function() { filter.appendTo(sidebar); });
            //.append(filterForm);
        }

        this.blur();
    });



    // $("#filter-button").click(function(ev){
    //     ev.preventDefault();
    //     $(".building-filter").toggleClass("hidden-xs");
    // });

});


