// play an embedded video
function playVideo(ev) {

    ev.preventDefault();

    var carousel = window.carousel;
    var elementSlide = this.parentNode;
    // build iframe element
    var iframe = document.createElement('iframe');
    iframe.classList.add('video-player');
    iframe.setAttribute('src', this.dataset.videoUrl);
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('allowfullscreen', true);
    iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; xr-spatial-tracking');

    // append iframe to player
    elementSlide.append(iframe);

    // pause the carousel and attach event handler
    console.log('pause...');
    carousel.carousel('pause');
    carousel.on('slide.bs.carousel', function(ev){
        console.log('unpause...');
        // destroy the iframe
        iframe.remove();
        // remove event handler and continue cycle
        carousel.off('slide.bs.carousel');
        carousel.carousel('cycle');
    });
}

$(function(){

    // manually enable carousel
    window.carousel = $('#detail-gallery').carousel();

    // find all buttons and add event listeners
    var buttons = document.querySelectorAll(".gallery-play-button");
    buttons.forEach(function(b) {
        b.addEventListener("click", playVideo);
    });

});






// // 2. This code loads the IFrame Player API code asynchronously.
// var tag = document.createElement('script');
// tag.src = "https://www.youtube.com/iframe_api";
// var firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);



// // 3. This function creates an <iframe> (and YouTube player)
// //    after the API code downloads.

// var player;
// function onYouTubeIframeAPIReady() {
//     var buttons = document.querySelectorAll(".gallery-play-button");
//     buttons.forEach(function(b) {
//         b.addEventListener("click", playVideoYoutube);
//     });
// }

// function playVideoYoutube(ev) {

//     ev.preventDefault();

//     var carousel = window.carousel;
//     var element = this.parentNode;
//     var id = this.dataset.videoId;

//     player = new YT.Player(element, {
//         height: '100%',
//         width: '100%',
//         videoId: id,
//         events: {
//             'onReady': onPlayerReady,
//             'onStateChange': onPlayerStateChange
//         }
//     });

//     carousel.carousel('pause');
//     carousel.on('slide.bs.carousel', function(ev){
//         // stop video on carousel change
//         player.stopVideo();
//         carousel.carousel('cycle');
//     });
// }


// // 4. The API will call this function when the video player is ready.
// function onPlayerReady(ev) {
//     console.log("Player ready...");
//     ev.target.playVideo();
// }


// // 5. The API calls this function when the player's state changes.
// //    The function indicates that when playing a video (state=1),
// //    the player should play for six seconds and then stop.
// // var done = false;
// function onPlayerStateChange(ev) {
//     // console.log("State Changed", event.data);
//     // if (event.data == YT.PlayerState.PLAYING) {
//     //     window.carousel.carousel("pause");
//     // }
// }