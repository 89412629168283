import './scss/rossetti.scss';

// load bootstrap
require('bootstrap');

// JQUERY
require('./js/gallery.js');
require('./js/alerts.js');
require('./js/show-filter.js');
require('./js/omni.js');

import { initMapbox } from './js/mapbox.js';
import { showLogin, showSignup } from './js/login.js';
import { handleFavorite } from './js/favorites.js';
import { initStickies } from './js/stickies.js';

// expose showLogin and showSignup to window
window.showLogin = showLogin;
window.showSignup = showSignup;
window.handleFavorite = handleFavorite;

function ready(fn) {
    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function() {

    // BOOTSTRAP subnavigation collapsing
    $(".nav-collapse").click(function(ev){
        ev.preventDefault();
        ev.stopPropagation();
        const el = $(ev.target.hash);
        el.collapse('toggle');
    });

    // BOOTSTRAP enable tooltips
    $(function () {
        $('[data-toggle="tooltip"]').tooltip({
            delay: 200,
            placement: 'auto',
            trigger: 'hover'
        })
    })


    $(".alert-dismissible").each(function(index, element) {

        const offset = index * 200;
        const showTime = offset;
        const dismissTime = 14500 + offset;

        window.setTimeout(function() {
            $(element).addClass('in show');
        }, showTime );

        window.setTimeout(function() {
            $(element).removeClass('in show');
            // alert('close');
            //slideUp(50);
        }, dismissTime );
    })

    $("#form-contact, #form-login, #form-signup").submit(function(event) {
        // alert( "Handler for .submit() called." );
        var btn = $('input.btn', this);
        var loading = $('.loading', this);
        btn.prop('disabled', true);
        loading.removeClass('d-none').addClass('d-flex');
    });


    // setup mapbox if needed
    // console.log(window.MAPBOX_OPTIONS);
    if (window.MAPBOX_OPTIONS) {
        initMapbox(window.MAPBOX_OPTIONS);
    }

    initStickies();

});