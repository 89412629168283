import { showSignup } from './login.js';

export function handleFavorite(ev) {

    ev.preventDefault();

    var el = this;
    var id = this.dataset.listing;
    var name = this.dataset.name;

    if (!account) {
        showSignup('favorite', id);
        console.log("Not Logged In!");
        return;
    }

    var data = {
        "listing": id
    }
    $(el).tooltip('hide');
    $.get( "/api/favorite/", data, function( data ) {

        var favoriteButtons = $('.widget-favorite a[data-listing="' + id + '"]');
        var tooltipLabel;
        var label;


        if (data.added) {
            var verb = data.active ? 'Unfollow' : 'Unsave';
            favoriteButtons.addClass('active');
            tooltipLabel = verb + ' ' + name;
            label = data.active ? 'Following' : 'Saved';
        } else if (data.deleted) {
            var verb = data.active ? 'Follow' : 'Save';
            favoriteButtons.removeClass('active');
            tooltipLabel = verb + ' ' + name;
            label = verb + ' ' + name;
        }

        // update the tooltip with the new label
        favoriteButtons.attr('data-original-title', tooltipLabel);
        favoriteButtons.each(function() {
            // update the label elements
            $(this.dataset.labelSelector).html(label);
        });
        // $(labelSelector).html(label);

        if (data.count) {
            $(".favorites-count").html('(' + data.count + ')');
        } else {
            $(".favorites-count").html('');
        }
    });
}