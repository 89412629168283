// const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

// MAPBOX INIT
export function initMapbox(options) {

    mapboxgl.accessToken = options.token;
    var map = null;
    var section = document.getElementById(options.section);

    // attach handler for bootstrap collapse event
    $(section).on('shown.bs.collapse', function () {
        if (map == null) {
            map = new mapboxgl.Map({
                container: options.el,
                style: 'mapbox://styles/rossettirealty/cjrr12oze4wxh2sjzrduckwmf',
                center: [options.lng, options.lat],
                zoom: 15
            });
            var marker = new mapboxgl.Marker()
                .setLngLat([options.lng, options.lat])
                .addTo(map);
        } else {
            map.resize();
        }
    });
}