require('selectize');
require('selectize/dist/css/selectize.css');
import { selectizeOptions } from '@/common/selectize.js'
// require('selectize/dist/css/selectize.default.css');
// require('selectize/dist/css/selectize.bootstrap3.css');

$(function(){

    var s = $('select[role="omni"]');

    if (s.data('behavior') != 'mls-building-filter' ) {
        // for 'building-filter' and 'home' filters, hijack the behavior
        selectizeOptions.onChange = function(value) {
            var selectize = this.$input[0].selectize;
            var item = selectize.options[value];
            if (item) {
                window.location.href = item.url;
            }
        }
    }

    s.selectize(selectizeOptions);

});
