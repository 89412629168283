
// custom event when the filter hash changes
// this can be dispatched from within the Vue app.
var hashChangeEvent = new Event("hashChange");

export function checkAlertButton(evt) {

    // console.log("Checking Alert Button... ", evt);

    var alertExists = window.store.getters.alertExists;
    var ctaAlertButton = $("#ctaAlertButton");
    var setAlertButton = $("#setAlertButton");
    var alertType = ctaAlertButton[0].dataset.alertType;
    var alertTypeLabel = alertType.substr(0,1).toUpperCase() + alertType.substr(1);

    // console.log("Alert Exists: ", alertExists);
    // console.log("ctaAlertButton: ", ctaAlertButton);
    // console.log("setAlertButton: ", setAlertButton);
    // console.log("alertTypeLabel: ", alertTypeLabel);

    if (ctaAlertButton && setAlertButton) {
        if (alertExists) {
            ctaAlertButton.html(alertTypeLabel + " Alert Set &nbsp; <i class='fa-bell fas'></i>");
            ctaAlertButton.addClass("active");
            ctaAlertButton.prop("disabled", true);
        } else {
            ctaAlertButton.html("Set "+ alertTypeLabel + " Alert &nbsp; <i class='fa-bell far'></i>");
            ctaAlertButton.removeClass("active");
            ctaAlertButton.prop("disabled", false);
            ctaAlertButton.click(function(evt) {
                // console.log("Setting Alert...");
                setAlertButton.click();
            });
        }
    }
}

$(function(){

    window.hashChangeEvent = hashChangeEvent;

    var ctaAlertButton = document.getElementById("ctaAlertButton");
    if (ctaAlertButton && typeof window.store !== "undefined") {
        window.addEventListener("hashChange", checkAlertButton);
        window.dispatchEvent(window.hashChangeEvent);
    }

    $(".alert-toggle").change(function(ev){
        // turn email alerts on/off for a given alert
        ev.preventDefault();
        var el = this;
        var id = this.dataset.id;
        var data = {
            "alert": id
        }
        $.get( "/api/alert/", data, function( data ) {
            console.log(data);
        });
    });

    var buildingAlertForms = document.getElementsByClassName('building-alert-form');
    var styleAlertForms = document.getElementsByClassName('style-alert-form');

    for (var form of buildingAlertForms) {
        form.onsubmit = function (ev) {
            if (!account) {
                ev.preventDefault();
                var data = {
                    region: this.region.value,
                    building: this.building.value,
                }
                window.showSignup(this.alertType.value, JSON.stringify(data));
                return;
            }
        }
    }

    for (var form of styleAlertForms) {
        form.onsubmit = function (ev) {
            if (!account) {
                ev.preventDefault();
                var data = {
                    region: this.region.value,
                    style: this.style.value,
                }
                window.showSignup(this.alertType.value, JSON.stringify(data));
                return;
            }
        }
    }


});
