export function registerPostAction(params) {

    // console.log("REQUEST:", params);

    $.post("/api/action/", params, function (data) {
        // console.log('RESPONSE:', data);
    });
}

export function showLogin(action, data) {

    registerPostAction({action, data});

    $('#login-signup-tabs a[href="#login-tab"]').tab('show');
    $("#login-modal").modal('show');
    $("#id_next").val(window.location);
    $("#id_signup-next").val(window.location);

    setTimeout(function () {
        $("#login-tab #id_username").focus();
    }, 500);
}

export function showSignup(action, data) {

    registerPostAction({action, data});

    $('#login-signup-tabs a[href="#signup-tab"]').tab('show');
    $("#login-modal").modal('show');
    $("#id_next").val(window.location);
    $("#id_signup-next").val(window.location);

    setTimeout(function () {
        $("#signup-tab #id_first_name").focus();
    }, 500);

}
